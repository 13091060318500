









































































































































































































































































































































































































































































































































































































































































































import VueJsonEditor from 'vue-json-editor'
import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'

@Component({ name: 'AgentConfig', components: { VueJsonEditor } })
export default class AgentConfig extends VueBase {
  async goBack() {
    this.$router.push({ name: 'sysInfo' })
  }
  private senior = false
  private json: any = {
    cluster_name: '',
    cluster_version: '',
    hostname: '',
    id: 0,
    ip: '',
    port: 443,
    priority: 0,
    user: 0,
    details: {
      enableAutoFallback: true,
      heavyTrafficBreakerWaitDuration: 30,
      heavyTrafficLimitInitBurstSeconds: 2.0,
      heavyTrafficLimitTokenPerSecond: 100.0,
      hookLimitInitBurstSeconds: 10,
      hookLimitTokenPerSecond: 5000,
      performanceBreakerFailureRate: 100,
      performanceBreakerWaitDuration: 40,
      performanceBreakerWindowSize: 2,
      performanceLimitRiskMaxMetricsCount: 3,
      secondFallbackDuration: 1000000000000,
      secondFallbackFrequencyInitBurstSeconds: 1000000000000,
      secondFallbackFrequencyTokenPerSecond: 10,
      performanceLimitMaxThreshold: {
        cpuUsage: {
          cpuUsagePercentage: 100,
        },
        garbageInfo: {
          collectionInfoList: [
            {
              tenured: true,
              collectionName: 'G1 Old Generation',
              collectionTime: 500,
              collectionCount: 5,
            },
          ],
        },
        memoryNoHeapUsage: {
          max: 1000000000000,
          init: null,
          used: 1000000000000,
          committed: null,
          memUsagePercentage: 100,
        },
        memoryUsage: {
          max: 1000000000000,
          init: null,
          used: 1000000000000,
          committed: null,
          memUsagePercentage: 100,
        },
        threadInfo: {
          threadCount: 10000,
          peakThreadCount: 10000,
          daemonThreadCount: 1000,
          dongTaiThreadCount: 10000,
        },
      },
      performanceLimitRiskThreshold: {
        cpuUsage: { cpuUsagePercentage: 100 },
        garbageInfo: {
          collectionInfoList: [
            {
              tenured: true,
              collectionName: 'G1 Old Generation',
              collectionTime: 1000,
              collectionCount: 10,
            },
          ],
        },
        memoryNoHeapUsage: {
          max: 1000000000000,
          init: null,
          used: 1000000000000,
          committed: null,
          memUsagePercentage: 100,
        },
        memoryUsage: {
          max: 1000000000000,
          init: null,
          used: 1000000000000,
          committed: null,
          memUsagePercentage: 100,
        },
        threadInfo: {
          threadCount: 10000,
          peakThreadCount: 10000,
          daemonThreadCount: 1000,
          dongTaiThreadCount: 10000,
        },
        secondFallbackDuration: 12000,
        secondFallbackFrequencyInitBurstSeconds: 200.0,
        secondFallbackFrequencyTokenPerSecond: 0.01,
      },
    },
  }
  private cut(arr: any, index: any) {
    if (arr.length === 1) {
      this.$message.error(this.$t('views.agentConfigEdit.onleOne') as string)
      return
    }
    return arr.splice(index, 1)
  }
  private add(arr: any) {
    arr.push({
      tenured: true,
      collectionName: 'G1 Old Generation',
      collectionTime: 1000,
      collectionCount: 10,
    })
  }
  private async save() {
    const res = await this.services.setting.save_threshold(this.json)
    if (res.status === 201) {
      this.$message.success(res.msg)
      this.goBack()
      return
    }
    this.$message.error(res.msg)
  }
  private async get_threshold_byid() {
    if (this.$route.query.id) {
      const res = await this.services.setting.get_threshold_byid(
        this.$route.query.id
      )
      if (res.status === 201) {
        this.json = res.data.result
      }
    }
  }
  async created() {
    this.get_threshold_byid()
  }
}
